<!-- 组织架构-学生架构 -->
<template>
    <div >
        <div class="content-main">
            <div class="filter-wrap">
                <expand-filter
                    :close-width="170"
                    :formData="formData"
                    marginBottom="0px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                >
                </expand-filter>
                <div class="button-line" v-hasPermi="['stuOrgan:add']"></div>
                <div >
                    <el-button v-hasPermi="['stuOrgan:add']" type="primary" @click="handleAdd">添加</el-button>
                    <el-button
                        v-hasPermi="['stuOrgan:import']"
                        v-show="isShowImportBtn" 
                        type="primary" 
                        @click="handleImport"
                    >导入</el-button>
                </div>
            </div>

            <table-data
                v-loading="loadingTable"
                ref="table"
                :config="table_config"
                :tableData="table_data"
            >
                <template v-slot:nameHeader="slotData">
                    <div class="name-header-slot">
                        <el-tooltip class="item" effect="dark" :content="contentText" placement="top">
                            <el-button
                                type="text"
                                @click="handleClick"
                                class="expand-all"
                                :class="{active: table_config.isExpandAll}"
                            >
                                <i class="el-icon-arrow-down" />
                                    <!-- <span>{{ !table_config.isExpandAll ? "展开" : "收起" }}</span> -->
                            </el-button>
                            </el-tooltip>
                        <span style="white-space: nowrap;">组织名称</span>
                    </div>
                </template>
                <template v-slot:nameContent="slotData">{{ slotData.data.name }}</template>
                <template v-slot:operation="slotData">
                    <el-button
                        type="text"
                        v-if="hasPermission(['stuOrgan:edit'])"
                        :disabled="operationButtonDisabled(slotData.data)"
                        @click="edit(slotData.data)"
                    >编辑</el-button>
                    <el-button
                        type="text"
                        v-if="hasPermission(['stuOrgan:addChild'])"
                        :disabled="operationButtonDisabled(slotData.data)"
                        @click="addc(slotData.data)"
                        v-show="slotData.data.organType !== '4'"
                    >添加下级</el-button>
                    <el-button
                        type="text"
                        v-if="hasPermission(['stuOrgan:isok'])"
                        :disabled="operationButtonDisabled(slotData.data)"
                        @click="graduate(slotData.data)"
                        v-show="slotData.data.organType == '3'"
                    >毕业</el-button>
                    <el-button
                        type="text"
                        v-if="hasPermission(['stuOrgan:batchAdjustSchoolNum'])"
                        :disabled="operationButtonDisabled(slotData.data)"
                        @click="showSchoolNumForm(slotData.data)"
                    >批调校区编号</el-button>
                    <el-button
                        type="text"
                        v-if="hasPermission(['stuOrgan:delete'])"
                        :disabled="operationButtonDisabled(slotData.data)"
                        @click="del(slotData.data)"
                    >删除</el-button>
                </template>
            </table-data>
            <dialog-wrapper
                :dialogObj="dialogObj"
                @handleClose="handleClose"
            >
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="94px"
                    class="common-form"
					label-position="right"
                >
                    <el-form-item
                        label="组织名称"
                        prop="name"
                    >
                        <el-input
                            v-model="ruleForm.name"
                            placeholder="组织名称"
                            show-word-limit
                            maxlength="20"
							class="input-width-medium"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="上级组织">
                        <select-tree
                            class="input-width-medium"
                            ref="treeRef"
                            :tree-option="treeOption"
                            :tree-show-data="treeShowData"
                            :rule-form="ruleForm"
                            @treeClick="treeClick"
                            @clearTree="clearTree"
                        />
                    </el-form-item>
                    <el-form-item
                        label="组织类型"
                        prop="organType"
                    >
                        <template v-if="ruleForm.parentOrg !== ''">
                            <!--显示部门，和全部层级-->
                            <el-radio
                                v-for="(item, index) of [
                                        
                                        ...types,
                                    ]"
                                :key="index"
                                v-model="ruleForm.organType"
                                :disabled="organTypeDisable"
                                :label="item.value"
                                @change="radioChange(item)"
                            >{{ item.label }}
                            </el-radio>
                        </template>
                        <template v-else>
                            <!--只显示部门，和层级第一个-->
                            <el-radio
                                v-for="(item, index) of [
                                        
                                        types[0],
                                    ]"
                                :key="index"
                                v-model="ruleForm.organType"
                                :disabled="organTypeDisable"
                                :label="item.value"
                                @change="radioChange(item)"
                            >{{ item.label }}
                            </el-radio>
                        </template>
                    </el-form-item>
                    <!-- 选中第二个类型时，显示阶段 -->
                    <el-form-item
                        v-if="ruleForm.organType === types[0].value"
                        label="阶段"
                        prop="greadStage"
                    >
                        <el-radio-group v-model="ruleForm.greadStage">
                            <el-radio
                                v-for="(item, index) in schoolStageList"
                                :key="index"
                                :label="item.schoolStage"
                            >
                                {{ item.schoolStageName }}
                            </el-radio>
                        </el-radio-group>
                        
                    </el-form-item>
                    <!-- 类型年级，显示入学年份 -->
                    <el-form-item
                        label="年份"
                        prop="joinTime"
                        v-if="ruleForm.organType == '3'"
                    >
                        <div style="display: flex; flex-direction: column">
                            <el-date-picker
                                class="input-width-medium"
                                v-model="ruleForm.joinTime"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="date"
                                placeholder="选择入学时间"
                            />
                            <div
                                style="
                                        width: 452px;
                                        font-size: 12px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                        line-height: 20px;
                                    "
                            >说明：请填写学生升入该阶段的年份，例如：阶段为初中，需输入学生升入初中一年级的年份；</div
                            >
                        </div>
                    </el-form-item>
                    <!-- 类型班级，阶段高中 -->
                    <el-form-item
                        v-if="
                                ruleForm.organType === '4' &&
                                ruleForm.greadStage === '1'
                            "
                        label="班级类型"
                        prop="classType"
                    >
                        <el-radio
                            v-model="ruleForm.classType"
                            :disabled="classTypeDisable"
                            label="1"
                        >行政班级
                        </el-radio
                        >
                        <el-radio
                            v-model="ruleForm.classType"
                            :disabled="classTypeDisable"
                            label="2"
                        >教学班级
                        </el-radio
                        >
                    </el-form-item>
                    <!-- 类型班级，班级类型教学班且阶段为高中 -->
                    <el-form-item
                        v-if="
                                ruleForm.organType === '4' &&
                                ruleForm.classType === '2' && 
                                ruleForm.greadStage === '1'
                            "
                        label="教学科目"
                        prop="classSubject"
                    >
                        <el-select
                            v-model="ruleForm.classSubject"
                            placeholder="教学科目"
                            class="input-width-medium"
                        >
                            <el-option
                                v-for="it in subjects"
                                :key="it.value"
                                :label="it.label"
                                :value="it.value"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="组织描述"
                        prop="remark"
                    >
                        <el-input
                            class="input-width-medium textarea"
                            type="textarea"
                            v-model="ruleForm.remark"
                            rows="10"
                            placeholder="请输入组织描述"
                            show-word-limit
                            maxlength="50"
                        ></el-input>
                    </el-form-item>
                    <div style="text-align: right;width:546px;">
                        <el-button
                            style="margin-left: 24px"
                            @click="handleClose"
                        >取消</el-button>
                        <el-button
                            type="primary"
                            @click="submitForm"
                        >确定</el-button>
                    </div>
                </el-form>
            </dialog-wrapper>
            <dialog-wrapper
                :dialogObj="schoolNumDialogObj"
                @handleClose="schoolNumClose"
            >
                <el-form
                    :model="schoolNumForm"
                    :rules="schoolNumRules"
                    ref="schoolNumForm"
                    class="common-form"
                    label-position="left"
                >
                    <el-form-item label="上次调整时间" label-width="auto">
                        <span>{{ schoolNumForm.schoolNumLastAdjustTime || '暂无' }}</span>
                    </el-form-item>
                    <el-form-item label="上次调整结果" label-width="auto">
                        <span v-if="schoolNumForm.schoolNumLastAdjustResult">调整为{{ schoolNumForm.schoolNumLastAdjustResult }}</span>
                        <span v-else>暂无</span>
                    </el-form-item>
                    <h3 style="margin-bottom: 18px">
                        你确定要将该组织架构下面的所有下级组织架构内的学生
                        进行批量调整校区编号吗？
                    </h3>
                    <el-form-item label="本次调整校区编号为：" prop="schoolNum">
                        <el-select
                            v-model="schoolNumForm.schoolNum"
                            placeholder="请选择校区编号"
                            clearable
                            filterable
                        >
                            <el-option
                                v-for="item in 10"
                                :label="item.toString()"
                                :value="item.toString()"
                                :key="item"
                            />
                        </el-select>
                    </el-form-item>
                    <div style="text-align: right;width:546px;">
                        <el-button
                            style="margin-left: 24px"
                            @click="schoolNumClose"
                        >取消</el-button>
                        <el-button
                            :loading="schoolNumBtnLoading"
                            type="primary"
                            @click="schoolNumSave"
                        >确定</el-button>
                    </div>
                </el-form>
            </dialog-wrapper>
            <!-- 导入弹窗 -->
        <dialog-wrapper 
            :dialogObj="importDialogData"
            @handleClose="importDialogClose"
        >
            <stu-import 
                ref="importRef" 
                :active="importActive" 
                :import-form="importForm"
                :type-options="importTypeOptions"
                :show-download="showDownload" 
                :upload-excel-header="uploadExcelHeader"
                :system-excel-header="systemExcelHeader" 
                :key-map="keyMap"
                :success-data="successData" 
                @download="downloadImportTemplate"
                @uploadChange="importUploadChange" 
                @uploadRemove="importUploadRemove" 
                @next="handleImportNext"
                @before="handleImportBefore" 
                @close="importDialogClose" 
                @importSelChange="importSelChange"
                @importTypeChange="importTypeChange"
                @save="handleImportSave" />
        </dialog-wrapper>
        </div>
    </div>
</template>

<script>
import {
    DialogWrapper,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableDataLine";
import {downloadFile, treeToList, hasPermission, parseTime} from "@/libs/utils.js"
import selectTree from "./Sub/selectTree2";
import ExpandFilter from "./Sub/ExpandFilter";
import StuImport from "@/components/scrollWrapper/SystemOrganizationalStuStructureScrollWrapper/StuImport/index.vue"
import { SummaryParentModel } from "@/models/SummaryParent"
import { SystemOrganizationalStuStructure } from "@/models/SystemOrganizationalStuStructure"
import XLSX2 from "xlsx"
import {mapState} from "vuex";
import onResize from "@/mixins/onResize";

const organType = [
    {label: "部门", value: "1"},
    {label: "级部", value: "2"},
    {label: "年级", value: "3"},
    {label: "班级", value: "4"},
];
export default {
    name: "SystemOrganizationalStuStructureScrollWrapper",
    mixins: [onResize],
    components: {
        selectTree,
        ExpandFilter,
        TableData,
        DialogWrapper,
        StuImport
    },
    data() {
        return {
            hasPermission,
            subjects: [],
            loadingTable: true,
            listQuery: {
                id: "",
                name: "",
                schoolId: "",
                organTypeList: ["2", "3", "4"],
                status: "",
            },
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "组织名称",
                        key: "name",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "1",
                        placeholder: "状态",
                        key: "status",
                        list: [
                            {
                                label: "未毕业",
                                value: "1",
                            },
                            {
                                label: "已毕业",
                                value: "2",
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["stuOrgan:list"],
                    },
                    // {
                    //     type: "primary",
                    //     text: "添加",
                    //     fn: "submit",
                    //     auth: ["stuOrgan:add"],
                    // },
                ],
                btnFormType: true
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        type: 'setHeader',
                        slotName: 'nameHeader',
                        slotName1: 'nameContent',
                        label: "组织名称",
                        prop: "name",
                        align: "left",
                        labelWidth: "200",
                        className:"target-name",
                        fixed: false
                    },
                    {
                        label: "组织类型",
                        prop: "organTypeName",
                        type: "function",
                        align: 'center',
                        labelWidth: "200",
                        callBack: (row, prop) => {
                            return {
                                1: "部门",
                                2: "级部",
                                3: "年级",
                                4: "班级",
                            }[row.organType];
                        },
                    },
                    {
                        label: "阶段",
                        prop: "greadStage",
                        type: "function",
                        labelWidth: "200",
                        align: 'center',
                        callBack: (row, prop) => {
                            if (row.organType === "1") {
                                return "-";
                            } else {
                                if (row.greadStage) {
                                    return {
                                        1: "高中",
                                        2: "初中",
                                        3: "小学",
                                        4: "幼儿园",
                                    }[row.greadStage];
                                } else if (row.classType) {
                                    return {
                                        1: "行政班级",
                                        2: "教学班级",
                                    }[row.classType];
                                }
                            }
                        },
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "状态",
                        prop: "status",
                        type: "function",
                        labelWidth: "200",
                        align: 'center',
                        callBack: (row, prop) => {
                            return {
                                1: "未毕业",
                                2: "已毕业",
                            }[row.status];
                        },
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "更新时间",
                        labelDescription: "最后更新时间",
                        prop: "updateTime",
                        labelWidth: "220px",
                        align: 'center',
                        type: 'function',
                        callBack: (row) => {
                            return row.updateTime ? row.updateTime.slice(0, -3) : row.createTime.slice(0, -3)
                        }
                    },
                    {
                        label: "组织描述",
                        prop: "remark",
                        align: 'center',
                        minWidth: "200"
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "260",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                rowkey: "id",
                isExpandAll: false,
                showIndex: false,
                indent: 27,
                height: "",
            },
            // 表格数据
            table_data: [],

            // 弹出框配置
            dialogObj: {
                title: "添加组织",
                dialogVisible: false,
                width: "640px",
            },
            // 下拉树显示数据
            treeShowData: "",
            // 下拉树组件配置
            treeOption: {
                placeholder: "请选择上级部门",
                key: "parentOrg",
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                treeData: [], // 展示数据
                disabled: false, // 是否可编辑
				width:'452px'
            },
            // 类型是否可编辑
            organTypeDisable: false,
            // 弹窗表单数据
            ruleForm: {
                parentOrg: "", // 父组织id
                name: "",
                remark: "",
                id: "",
                organType: "",
                greadStage: "",
                classType: "",
                classSubject: "",
                schoolId: "",
                parentList: "",
                joinTime: "",
                
            },
            // 校验规则
            rules: {
                joinTime: [
                    {
                        required: true,
                        message: "请选择年份",
                        trigger: "change",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入组织名称",
                        trigger: "blur",
                    },
                ],
                organType: [
                    {
                        required: true,
                        message: "请选择组织类型",
                        trigger: "change",
                    },
                ],
                classType: [
                    {
                        required: true,
                        message: "请选择班级类型",
                        trigger: "change",
                    },
                ],
                classSubject: [
                    {
                        required: true,
                        message: "请选择教学科目",
                        trigger: "change",
                    },
                ],
                greadStage: [
                    {
                        required: true,
                        message: "请选择阶段",
                        trigger: "change",
                    },
                ],
            },
            list: [],
            types: [],
            parentOrganization: {
                id: "",
                name: "",
                schoolId: "",
                pageNum: 1
            },
            parentOrganizationData: [],
            // 导入学生架构弹窗数据
            importDialogData: {
                title: "导入",
                dialogVisible: false,
                width: "740px",
            },
            // 导入步骤
            importActive: 0,
            // 导入数据
            importForm: {
                file: "",
                type: "",
                errType: "1"
            },
            showDownload: true,
            // 上传表头数据
            uploadExcelHeader: [],
            // 系统标准字段名称
            systemExcelHeader: [],
            keyMap: {},
            successData: 0,
            tableJsonData: [],
            importTypeOptions: [
                {
                    label: "行政班级",
                    value: "1",
                },
                {
                    label: "教学班级",
                    value: "2",
                },
            ],
            classTypeDisable: false, // 班级类型是否禁用
            open: false,
            schoolNumDialogObj: {
                title: "批量调整校区编号",
                dialogVisible: false,
                width: "640px",
            },
            schoolNumBtnLoading: false,
            schoolNumForm: {
                id: "",
                schoolId: "",
                schoolNum: "",
                parentList: "",
                schoolNumLastAdjustTime: '',
                schoolNumLastAdjustResult: '',
            },
            schoolNumRules: {
                schoolNum: [
                    {
                        required: true,
                        message: "请选择校区编号",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState({
            schoolStageList: (state) => state.schoolStageList,
        }),
        isShowImportBtn() {
            let statusValOfFormData = this.formData.data.filter(item => item.key == "status")[0].value;
            if(statusValOfFormData == "2"){
                return false;
            } else {
                return true;
            }
            // return true;
        },
        contentText() {
            return this.open ? '点击收起' : '点击展开'
        }
    },
    async created() {
        this.ruleForm.schoolId = this.listQuery.schoolId =
            this.$store.state.schoolId;
        this.parentOrganization.schoolId = this.$store.state.schoolId;
        this.getTypes();
        this.formData.data.forEach((item) => {
            this.listQuery[item.key] = item.value
        });
        await this.getList();
        await this.getDictList();
    },
    mounted() {
    },

    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.$refs.table) {
            this.$refs.table.$refs.multipleTable.doLayout();
        }
    },
    methods: {
        // 列表数据
        async getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            await this._fet(
                "/school/schoolOrgan/listByConditionNoAuth",
                this.listQuery,
            ).then((res) => {
                this.handleRes(res, () => {
                    this.list = res.data.data.list;


                    let dataList = this.formatRouterTree(
                        JSON.parse(JSON.stringify(res.data.data.list)),
                        "id",
                        "parentOrg",
                    );

                    this.dataFilter(dataList);
                    console.debug(JSON.parse(JSON.stringify(dataList)), "dataList");
                    this.table_data = dataList;
                    this.getParentOrganizationData();
                    this.loadingTable = false;
                });
            });
        },
        /**
         * @Description: 数据处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-7-15 11:10:09
         */
         dataFilter(data) {
            this.maxLevel = 1;
            this.getLevel(data, 1);
            console.debug(this.maxLevel, "最大层级");
            this.treeFilter2(data, 1);
            this.addEndData(data);
        },
        /**
         * @Description: 获取几级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 14:05:14
         */
         getLevel(data, level) {
            data.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    if (this.maxLevel <= level + 1) {
                        this.maxLevel = level + 1;
                    }
                    this.getLevel(item.children, level + 1);
                }
            });
        },
        /**
         * @Description: 树形数据处理,给数据添加等级字段,是否展开字段,是否时最后一级字段
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-16 14:05:14
         */
         treeFilter2(data, level) {
            data.forEach((item) => {
                item.level = level;
                item.isOpen = false;
                item.isLast = false;
                item.isEnd = false;
                if (item.children && item.children.length > 0) {
                    this.treeFilter2(item.children, level + 1);
                }
            });
        },
        /**
         * @Description: 给从第二级开始的最后一级并且没有下级的行数据添加isEnd
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-18 15:01:15
         */
         addEndData(data) {
            data.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    this.addEndData2(item.children);
                }
            });
        },
        /**
         * @Description: 给从第二级开始的最后一级并且没有下级的行数据添加isEnd
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-18 15:01:15
         */
        addEndData2(data) {
            let last = data[data.length - 1];
            if (last.children && last.children.length > 0) {
                this.addEndData2(last.children);
            } else {
                last.isEnd = true;
            }
        },
        // 科目数据
        async getDictList() {
            await this._fet("/school/schoolDictValue/listByCondition", {
                schoolId: this.listQuery.schoolId,
                dictKey: "teachingSubjects",
            }).then((res) => {
                this.subjects = res.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            });
        },
        async getParentOrganizationData () {
            await this._fet(
                "/school/schoolOrgan/listByCondition",
                this.listQuery,
            ).then((res) => {
                if (res.data.code == '200') {
                    this.parentOrganizationData = res.data.data.list;
                    this.treeOption.treeData = this.formatRouterTree(
                        JSON.parse(JSON.stringify(res.data.data.list)).filter(
                            // (i) => i.organType !== "4",
                            (i) => ['2','3'].includes(i.organType),
                        ),
                        "id",
                        "parentOrg",
                    );
                }
            })
        },
        // 获取类型数据
        getTypes() {
            let gradeLevelName = this.$store.state.gradeLevelName || "";
            if (gradeLevelName) {
                let data = [];
                if (gradeLevelName.includes("&lt")) {
                    data = gradeLevelName.split("&lt;");
                } else if (gradeLevelName.includes("<")) {
                    data = gradeLevelName.split("<");
                }
                data.forEach((item) => {
                    let find = organType.find((i) => i.label === item);
                    if (find) {
                        this.types.push({label: item, value: find.value});
                    }
                });
                if (this.types.length > 0) {
                    this.ruleForm.organType = this.types[0].value;
                }
            }
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "submit": // 添加
                    this.dialogObj.title = "添加学生组织";
                    this.dialogObj.dialogVisible = true;
                    break;
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    this.listQuery.name = "";
                    this.listQuery.id = "";
                    this.listQuery.status = "";
                    this.getList(1);
                    break;
            }
        },
        handleAdd(){
            this.dialogObj.title = "添加学生组织";
            this.dialogObj.dialogVisible = true;
        },
        async del(data) {
            let allowDeleteRes = await this._fet("/school/schoolOrgan/allowDelete",{id:data.id});
            if(allowDeleteRes.data.code != '200'){
                this.$message.error(allowDeleteRes.data.msg);
                return;
            }
            this.$confirm(
                "是否确定删除？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    this._fet("/school/schoolOrgan/delete", {
                        id: data.id,
                        name: data.name,
                    }).then((res) => {
                        this.handleRes(res, () => {
                            this.getList();
                            this.$message.success("已删除");
                        });
                    });
                })
                .catch(() => {});
        },
        // 编辑
        async edit(data) {
            await this.hasStudentCount(data.id);
            this.dialogObj.title = "编辑学生组织";
            this.organTypeDisable = true;
            let parent = this.parentOrganizationData.find((i) => i.id === data.parentOrg);
            if (parent) {
                this.treeShowData = parent.name; // 上级组织显示数据赋值
            }
            this.$nextTick(() => {
                Object.keys(this.ruleForm).forEach((key) => {
                    this.ruleForm[key] = data[key];
                });
            });
            this.dialogObj.dialogVisible = true;
        },
        /**
         * @Description: 根据组织机构id获取班级下学生人数学生
         * @Author: 闫乔
         * @Date: 2024-08-15 10:54:47
         * @param {*} id
         */        
        async hasStudentCount(id) {
            this.classTypeDisable = false;
            await this._fet("/school/schoolOrgan/getClassStudentCount",{id: id}).then(
                (res) => {
                    this.handleRes(res, () => {
                        let haveStudentCount = res.data.data;
                        if (Number(haveStudentCount) > 0) {
                            this.classTypeDisable = true;
                        }
                        console.log('this.classTypeDisable班级类型是否禁用',this.classTypeDisable);
                        
                    });
                },
            );
        },
        // 类型切换，清空之前选中数据
        radioChange() {
            this.ruleForm.classType = "";
            this.ruleForm.greadStage = "";
            this.ruleForm.classSubject = "";
        },
        // 添加下级
        addc(data) {
            this.dialogObj.title = "添加下级";
            this.treeOption.disabled = true; // 上级下拉不可编辑
            this.treeShowData = data.name; // 上级组织显示名称
            this.dialogObj.dialogVisible = true;
            this.organTypeDisable = true; // 类型不可编辑
            this.$nextTick(() => {
                this.ruleForm.parentOrg = data.id;
                this.ruleForm.greadStage = data.greadStage;
                this.ruleForm.joinTime = ""
                // 部门下只可添加部门
                // 级部下只可添加年级
                // 年级下只可添加班级
                if (data.organType === "1") {
                    this.ruleForm.organType = "1";
                } else {
                    this.types.forEach((item, index) => {
                        if (item.value === data.organType) {
                            this.ruleForm.organType =
                                this.types[index + 1].value;
                        }
                    });
                }
            });
        },
        // 上级部门下拉清除
        clearTree() {
            this.organTypeDisable = false; // 类型可编辑
            this.ruleForm.organType = this.types[0].value; // 赋值为组织架构第一级
            this.ruleForm.parentOrg = "";
        },
        // 上级部门下拉选中
        treeClick(data) {
            // 上级部门就是自己
            if (data.id === this.ruleForm.id) {
                return this.$message.warning("不允许选择本组织及下级组织");
            }
            // 如果选择的节点存在父级并且是编辑状态
            if(data.parentOrg && this.ruleForm.id){
                let resultIds = this.findAncestors(this.list,data.id);
                if(resultIds.includes(this.ruleForm.id)){
                    return this.$message.warning("不允许选择本组织及下级组织");
                }
                
            }
            this.organTypeDisable = true; // 类型不可编辑
            this.ruleForm.greadStage = data.greadStage;
            this.ruleForm.parentOrg = data.id;
            this.ruleForm.classSubject = "";
            if (!this.classTypeDisable) {
                this.ruleForm.classType = "";
            }
            this.treeShowData = data.name;
            this.$refs.treeRef.$refs.elSelect.blur();
            // 部门下只可添加部门
            // 级部下只可添加年级
            // 年级下只可添加班级
            if (data.organType == "1") {
                this.ruleForm.organType = "1";
            } else {
                this.types.forEach((item, index) => {
                    if (item.value === data.organType) {
                        this.ruleForm.organType = this.types[index + 1].value;
                    }
                });
            }
        },
        // 添加，编辑
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // 存在上级,拼接parentList数据
                    if (this.ruleForm.parentOrg !== "") {
                        const plist = [];
                        for (
                            var i = this.ruleForm;
                            (i = this.list.find((it) => it.id == i.parentOrg));

                        ) {
                            plist.push(i.id);
                        }
                        this.ruleForm.parentList = plist.reverse().join();
                    } else {
                        // 不存在上级置空
                        this.ruleForm.parentList = "";
                    }
                    // 行政班清空教学班科目数据
                    if (this.ruleForm.greadStage !== "1") {
                        this.ruleForm.classType = "1";
                        this.ruleForm.classSubject = "";
                    }
                    // if (this.ruleForm.classType === '1') this.ruleForm.classSubject = ''
                    this._fet("/school/schoolOrgan/save", this.ruleForm).then(
                        (res) => {
                            this.handleRes(res, () => {
                                this.getList();
                                this.$message.success("操作成功");
                                this.handleClose();
                            });
                        },
                    );
                }
            });
        },

        // 弹窗关闭
        handleClose() {
            this.$refs.ruleForm.resetFields();
            this.ruleForm.id = "";
            this.ruleForm.parentOrg = ""; // 上级部门选中数据置空
            this.ruleForm.joinTime = "";
            this.treeShowData = "";
            this.$refs.treeRef.$data.filterText = "";
            this.treeOption.disabled = false; // 上级部门可编辑
            this.organTypeDisable = false; // 类型可编辑
            this.dialogObj.dialogVisible = false; // 关闭弹窗
        },
        handleClick(isAss) {
            //this.$refs.ctab.handleExpand(isAss)
            this.open = !this.open; 
            this.handleExpand(isAss);
        },
        handleExpand(isAss) {
            // 此判断是否为展开状态或折叠状态--若展开折叠是同一个按钮则无需传参和加此if
            this.table_config.isExpandAll = !this.table_config.isExpandAll;
            // this.tableData为返回的二级数据data
            this.$nextTick(() => {
                this.forArr(this.list, this.table_config.isExpandAll);
            });
        },
        // 遍历
        forArr(arr, isExpand) {
            arr.forEach((i) => {
                // toggleRowExpansion见element文档
                this.$refs.table.$refs.multipleTable.toggleRowExpansion(
                    i,
                    isExpand,
                );
                if (i.children) {
                    this.forArr(i.children, isExpand);
                }
            });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        formatRouterTree(data, key, parentKey) {
            let parents = [];
            let children = [];
            data.forEach((item, index) => {
                let find = data.find((i) => item[parentKey] === i[key]);
                if (find && item[parentKey] !== undefined) {
                    children.push(item);
                } else {
                    parents.push(item);
                }
            });
            dataToTree(parents, children, key, parentKey);

            function dataToTree(parents, children, key, parentKey) {
                parents.map((p) => {
                    children.map((c, i) => {
                        if (c[parentKey] === p[key]) {
                            let _c = JSON.parse(JSON.stringify(children));
                            _c.splice(i, 1);
                            dataToTree([c], _c, key, parentKey);
                            if (p.children) {
                                p.children.push(c);
                            } else {
                                p.children = [c];
                            }
                        }
                    });
                });
            }

            return parents;
        },
        /**
         * @Description: 毕业
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-08-01 16:37:09
         * @param {*} data
         */        
        async graduate(data) {
            let isHavePerson = await this.hasPerson(data.id);
            // 毕业后，该组织内学生状态变为毕业，学生相关信息将不再显示，组织下班级将不可编辑，是否继续?
            let tipMsg = isHavePerson ? 
                    "毕业后，该组织内学生状态变为毕业，学生相关信息将不再显示，组织下班级将不可编辑，是否继续?" : 
                    "已毕业年级下所有班级将不可编辑，是否继续？"
            this.$confirm(tipMsg,"提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            ).then(async () => {
                let res = await this._fet("/school/schoolOrgan/graduate",
                    {
                        id: data.id,
                        schoolId: this.$store.state.schoolId
                    });
                    this.handleRes(res, () => {
                        this.$message.success("修改成功")
                        this.getList(1)
                    }) 
            }).catch(() => {});
        },
        /**
         * @Description: 获取年级下是否有学生
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-08-02 08:49:55
         * @param {*} id id
         */        
        async hasPerson(id) {
            let res = await this._fet("/school/schoolOrgan/hasPerson",{id: id});
            if(res.data.code == '200') {
                return false
            } else {
                return true
            }
        },
        handleImport() {
            this.importDialogData.dialogVisible = true
        },
        /**
         * @Description: 导入学生架构弹窗关闭
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-08-01 10:00:30
         */        
        importDialogClose() {
            this.getList(1)
            this.importDialogData.dialogVisible = false
            this.showDownload = true
            this.importActive = 0
            this.importForm.file = ''
            this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles()
            this.$refs.importRef.$refs.importRef.$data.fileName = ''
        },
        /**
         * @Description: 导入下载模板
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-08-01 10:09:02
         */
        downloadImportTemplate() {
            if(!this.importForm.type){
                 this.$message.warning("请先选择导入类型");
                 return
            }
            let form = {
                schoolId: this.$store.state.schoolId,
                type: this.importForm.type
            }
            // this._fet("/school/schoolOrgan/template",{
            //     schoolId: this.$store.state.schoolId,
            //     type: this.importForm.type
            // }).then((res) => {
            //     if (res.data.code === "200") {
            //         downloadFile({
            //             url: res.data.data,
            //             method: "get"
            //         }, () => {
            //         }, () => {
            //             this.$message.error("请求处理异常，请稍后再试")
            //         })
            //     } else {
            //         this.$message.error("请求处理异常，请稍后再试")
            //     }
            // })
            downloadFile(
                {
                    url: '/school/schoolOrgan/template', form
                },
                () => {},
                () => {}
            )
        },
        async importUploadChange(file) {
            this.importForm.file = file.raw
            let dataBinary = await this.readFile(file.raw)
            let workBook = XLSX2.read(dataBinary, {
                type: 'binary',
                cellDates: true
            })
            let workSheet = workBook.Sheets[workBook.SheetNames[0]]
            this.tableJsonData = XLSX2.utils.sheet_to_json(workSheet)
            this.uploadExcelHeader = []
            let header = []
            const range = XLSX2.utils.decode_range(workSheet['!ref'])
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + '1')
            }
            header.forEach((item) => {
                this.uploadExcelHeader.push({
                    label: workSheet[item].v,
                    disabled: false
                })
            })
        },
        readFile(file) {
            return new Promise(resolve => {
                let reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onload = ev => {
                    resolve(ev.target.result)
                }
            })
        },
        getNewData() {
            let newData = []
            // 上传原excel文件数据   tableJsonData
            // 上传原excel表头数据   uploadExcelHeader
            this.tableJsonData.forEach((item) => {
                let obj = {}
                this.uploadExcelHeader.forEach((itm) => {
                    const find = Object.keys(this.keyMap).find(i => this.keyMap[i] === itm.label)
                    if (find) {
                        obj[find] = item[this.keyMap[find]]
                    } else {
                        obj[itm.label] = item[itm.label]
                    }
                })
                newData.push(obj)
            })
            const ws = XLSX2.utils.json_to_sheet(newData)
            const wb = XLSX2.utils.book_new()
            XLSX2.utils.book_append_sheet(wb, ws, "学生信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            return new File([arrayBuffer], '学生信息导入模板.xlsx')
        },
        importUploadRemove() {
            this.importForm.file = ''
        },
        // 导入相关方法
        handleImportSave() {
            // 系统必填标准字段
            const requiredField = this.systemExcelHeader.filter(i => i.required === true)
            // keyMap 选中的对应关系
            let find = requiredField.find((item) => this.keyMap[item.label] === '')
            if (find) {
                return this.$message.warning('缺少必须导入字段名称')
            }
            const systemOrganizationalStuStructure = new SystemOrganizationalStuStructure()
            // 根据选中的字段对应关系，生成新的excel文件
            const file = this.getNewData()
            console.log('文件', file)
            let formData = new FormData()
            formData.append('file', file)
            formData.append('errType', this.importForm.errType)
            formData.append('schoolId', this.$store.state.schoolId)
            formData.append('importType', this.importForm.type)
            systemOrganizationalStuStructure.importOrganInfo(formData).then((res) => {
                if (res.data.code === '200') {
                    this.importActive++
                    this.successData = res.data.data.rightCount;
                    this.getParentOrganizationData()
                } else if (res.data.code === '400') {
                    this.$message.error('请求异常，请稍后再试！')
                } else {
                    this.downloadErrorList(res.data)
                }
            })
        },
        importSelChange(data) {
            this.uploadExcelHeader.forEach((item) => {
                let find = Object.keys(this.keyMap).find(k => this.keyMap[k] === item.label)
                item.disabled = !!find
            })
        },
        handleImportBefore() {
            this.importActive--
            this.showDownload = false
        },
        handleImportNext() {
            if (this.importForm.file === '') {
                return this.$message.warning("上传填好的学生架构表")
            }
            if (!this.importForm.type) {
                return this.$message.warning("请选择班级类型")
            }
            this.importActive++
            if (this.importActive == 1) {
                this.getKeyMap()
            }
        },
        getKeyMap() {
            this.keyMap = {}
            this.systemExcelHeader.forEach((item) => {
                let header = this.uploadExcelHeader.find(e => e.label == item.label)
                this.keyMap[item.label] = header?.label ?? ''
            })
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                downloadFile({
                    url: '/school/schoolOrgan/errorInfoExport',
                    method: 'post',
                    fileName: '导入错误信息表.xlsx',
                    form: {
                        paramData: data.info.paramData
                    }
                }, () => {
                }, () => {
                    this.$message.error('请求处理异常，请稍后再试')
                })
            }).catch(() => {
            })
        },
        rowClassNameFn(row) {
            let { status } = row.row;
            // 如果已毕业
            if(status == '2'){
                return "special-row"
            }
            return ''
        },
        operationButtonDisabled(row){
            return row.status == '2'
        },
        /**
         * @Description: 根据类型（行政班级/教学班级）设置系统标砖字段名称
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 张冰华
         * @Date: 2024-08-05 09:13:56
         * @param { String } type 1行政班级,2教学班级
         */        
        setSystemExcelHeader(type) {
            if(!type) {
                return 
            }
            
            const _this = this;
            let gradeTypes = this.types.map(item => {
                return {
                    label: item.label,
                    required: true,
                }
            })
            let switchObj = {
                // 行政班级
                "1":function(){
                    _this.systemExcelHeader = [
                        {
                            label: '阶段',
                            required: true
                        },
                        {
                            label: '年份',
                            required: true
                        },
                        ...gradeTypes,
                        {
                            label: '组织描述',
                            required: false
                        }
                    ]
                },
                "2":function(){
                    _this.systemExcelHeader = [
                        {
                            label: '阶段',
                            required: true
                        },
                        {
                            label: '年份',
                            required: true
                        },
                        ...gradeTypes,
                        {
                            label: '教学科目',
                            required: true
                        },
                        {
                            label: '组织描述',
                            required: false
                        }
                    ]
                },
            }
            switchObj[type]();
        },
        importTypeChange(val) {
            this.setSystemExcelHeader(val);
        },
        /**
         * @Description: 根据id获取祖先节点
         * @Author: 张冰华
         * @Date: 2024-08-06 14:35:16
         * @param {*} arr 拍平后的数组
         * @param {*} id id
         * @param {*} idKey
         * @param {*} parentKey
         * @param {*} ancestors
         * @return { Arrary } ancestors id集合
         */        
        findAncestors(arr, id, idKey = 'id', parentKey = 'parentOrg', ancestors) {
            if (!ancestors) {
                ancestors = [];
            }
            let current = arr.find(item => item[idKey] === id);

            if (current && current[parentKey]) {
                ancestors.push(current[parentKey]);
                this.findAncestors(arr, current[parentKey], idKey, parentKey, ancestors)
            }

            return ancestors;
        },
        showSchoolNumForm(data) {
            Object.keys(this.schoolNumForm).forEach((key) => {
                if (data[key]) {
                    this.schoolNumForm[key] = data[key]
                }
            })
            if (this.schoolNumForm.schoolNumLastAdjustTime) {
                this.schoolNumForm.schoolNumLastAdjustTime = parseTime(this.schoolNumForm.schoolNumLastAdjustTime, 'y年m月d日 h:i')
            }
            this.schoolNumDialogObj.dialogVisible = true
        },
        schoolNumSave() {
            this.$refs.schoolNumForm.validate((val) => {
                if (val) {
                    this.schoolNumBtnLoading = true
                    let form = this._.cloneDeep(this.schoolNumForm)
                    delete form.schoolNumLastAdjustTime
                    delete form.schoolNumLastAdjustResult
                    this._fet('/school/schoolOrgan/batchAdjustSchoolNum', form).then((res) => {
                        if (res.data.code === '200') {
                            this.$message.success('批量调整校区编号成功')
                            this.getList()
                            this.schoolNumClose()
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg)
                        }
                    }).finally(() => {
                        this.schoolNumBtnLoading = false
                    })
                }
            })
        },
        schoolNumClose() {
            this.schoolNumDialogObj.dialogVisible = false
            this.schoolNumForm = this.$options.data.call(this).schoolNumForm
            this.$nextTick(() => {
                this.$refs.schoolNumForm.clearValidate()
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.content-wrapper {
    border-radius: 4px;
    margin-top: 0;
}
.filter-wrap {
    display: flex;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}


.table-data {
    border-radius: 4px;
    overflow: hidden;
}
::v-deep .el-table {
    .el-table__row .el-table__cell:first-child .cell {
        padding-left: 62px;
    }

    [class*=el-table__row--level] {
        .el-table__expand-icon {
            margin-right: 4px;

            &:hover .el-icon-arrow-right {
                background-color: #3c7fff;
            }
        }

        .el-table__expand-icon .el-icon-arrow-right {
            transform: rotate(90deg);
            color: #FFFFFF;
            font-size: 13px;
            width: 16px;
            height: 16px;
            line-height: 17px;
            text-align: center;
            background-color: #bfbfbf;
            border-radius: 50%;
            transition: rotate 0.2s ease-in;
        }

        .el-table__expand-icon--expanded .el-icon-arrow-right {
            transform: rotate(-180deg) !important;
            background-color: #3c7fff;
        }

        .el-table__placeholder {
            width: 0;
        }
    }

    .special-row {
        td {
            color: #909399 !important;

            &:nth-child(2) {
                color: #666 !important;
            }
        } 
    }
}

.name-header-slot {
    display: flex;
    align-items: center;

    .expand-all {
        display: flex;
        align-items: center;
        margin-right: 16px;
        height: 23px;
        padding: 0;

        .el-icon-arrow-down {
            width: 16px;
            height: 16px;
            line-height: 18px;
            text-align: center;
            font-size: 13px;
            border-radius: 50%;
            color: #FFFFFF;
            background-color: #bfbfbf;
            transition: rotate 0.2s ease-in;
        }

        span {
            margin-left: 5px;
            font-size: 14px;
            color: #595959;
            font-family: Microsoft YaHei;
        }

        &:hover {
            span {
                color: #3c7fff;
            }

            .el-icon-arrow-down {
                color: #ffffff;
                background-color: #3c7fff;
            }
        }
    }

    .active {
        .el-icon-arrow-down {
            transform: rotate(-180deg);
            background-color: #3c7fff;
        }

        span {
            color: #3c7fff;
        }
    }
}

.textarea {
    /deep/ .el-textarea__inner {
        color: #606266;
        font-size: 14px;
    }

}
</style>
